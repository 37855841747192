import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const http = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 20000,
    headers: { 'Content-Type': 'application/json' }
});

http.interceptors.request.use(
    async (config) => {
        const token = localStorage.getItem('jwtAccessToken');

        if (token) {
            config.headers.credentials = 'include';
            config.headers['X-PackBack-Auth'] = `${token}`;
            config.headers['Access-Control-Allow-Origin'] = '*';
            config.headers['Content-Type'] = 'application/json';
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Axios middleware to convert all api responses to camelCase
http.interceptors.response.use(
    (response) => {
        if (response.data && response.headers['content-type'] === 'application/json') {
            response.data = camelizeKeys(response.data);
        }
        return response;
    },
    async (error) => {
        console.log('[HTTP AXIOS] Error:', error);
        if (error.response && error.response.status === 401) {
        } else return Promise.reject(error);
    }
);

// Axios middleware to convert all api requests to snake_case
http.interceptors.request.use((config) => {
    const newConfig = { ...config };
    const { url } = newConfig;

    if (newConfig.headers['Content-Type'] === 'multipart/form-data') return newConfig;

    const splittedURL = url.split('/');

    // use this code when nodejs is in use
    if (splittedURL && splittedURL[1] && splittedURL[1] !== 'v1') {
        return newConfig;
    }

    if (config.params) {
        newConfig.params = decamelizeKeys(config.params);
    }
    if (config.data) {
        newConfig.data = decamelizeKeys(config.data);
    }

    return newConfig;
});
export default http;
