import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import { showMessage } from './messageSlice';

const initialState = {
    loading: false,
    success: false,
    error: false,
    getReusable: {
        loading: false,
        success: false,
        error: false,
        data: []
    },
    returnReusable: {
        loading: false,
        success: false,
        error: false,
        data: []
    }
};

export const getReusable = createAsyncThunk('getReusable', async ({ code }, { dispatch, rejectWithValue, getState }) => {
    try {
        const response = await api.getReusable(code);
        dispatch(
            showMessage({
                variant: 'success',
                message: response?.data?.data?.name ? 'Scanned: ' + response?.data?.data?.name : 'Scanned Successfully'
            })
        );

        return response?.data?.data;
    } catch (e) {
        dispatch(showMessage({ variant: 'error', message: e?.response?.data?.message || e?.message || 'Something went wrong' }));
        return rejectWithValue(e?.response?.data);
    }
});

export const returnReusable = createAsyncThunk('returnReusable', async ({ codes }, { dispatch, rejectWithValue, getState }) => {
    try {
        const response = await api.returnReusable(codes);

        return response?.data?.data;
    } catch (e) {
        dispatch(showMessage({ variant: 'error', message: e?.response?.data?.message || e?.message || 'Something went wrong' }));
        return rejectWithValue(e?.response?.data);
    }
});

const reusableSlice = createSlice({
    name: 'reusable',
    initialState,
    reducers: {
        clearReusableState: (state, action) => initialState,
        clearGetReusableState: (state, action) => initialState.getReusable,
        deleteGetReusableItem(state, action) {
            const index = state.getReusable.data.findIndex((item) => item.id === action.payload.id);
            state.getReusable.data.splice(index, 1);
        }
    },
    extraReducers(builder) {
        builder

            .addCase(getReusable.pending, (state) => {
                state.getReusable.loading = true;
                state.getReusable.success = false;
                state.getReusable.error = false;
            })
            .addCase(getReusable.fulfilled, (state, action) => {
                state.getReusable.loading = false;
                state.getReusable.success = true;
                state.getReusable.error = false;
                state.getReusable.data = [...state.getReusable?.data, action?.payload];
            })
            .addCase(getReusable.rejected, (state, action) => {
                state.getReusable.loading = false;
                state.getReusable.success = false;
                state.getReusable.error = action?.payload?.errors || action?.payload?.message;
            })
            .addCase(returnReusable.pending, (state) => {
                state.returnReusable.loading = true;
                state.returnReusable.success = false;
                state.returnReusable.error = false;
            })
            .addCase(returnReusable.fulfilled, (state, action) => {
                state.returnReusable.loading = false;
                state.returnReusable.success = true;
                state.returnReusable.error = false;
                state.returnReusable.data = action?.payload;
            })
            .addCase(returnReusable.rejected, (state, action) => {
                state.returnReusable.loading = false;
                state.returnReusable.success = false;
                state.returnReusable.error = action?.payload?.errors || action?.payload?.message;
            });
    }
});

export const { clearReusableState, clearGetReusableState, deleteGetReusableItem } = reusableSlice.actions;
export default reusableSlice.reducer;
// export const {} = analyticsSlice.actions;
