import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Snackbar, StyledEngineProvider } from '@mui/material';

// routing
import { ProtectedRoutes, PublicRoutes } from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { getLoggedInUser } from './store/slices/authSlice';
import { Alert } from '@mui/lab';
import PackBackMessage from './ui-component/extended/Message';
import Loader from './ui-component/Loader';

// ==============================|| APP ||============================== //

const App = () => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const customization = useSelector((state) => state.customization);

    useEffect(() => {
        dispatch(getLoggedInUser({}));
    }, [dispatch]);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <PackBackMessage />
                {auth?.autoLogin?.loading ? (
                    <Loader />
                ) : (
                    <NavigationScroll>{auth?.data?.id ? <ProtectedRoutes /> : <PublicRoutes />}</NavigationScroll>
                )}
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
