// assets
import { IconDashboard, IconScan } from '@tabler/icons';

// constant
const icons = { IconDashboard, IconScan };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'scan',
    title: 'Scan',
    type: 'group',
    children: [
        {
            id: 'default',
            title: 'Scan',
            type: 'item',
            url: '/scan',
            icon: icons.IconScan,
            breadcrumbs: false
        }
    ]
};

export default dashboard;
