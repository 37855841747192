import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../../api';
import jwtDecode from 'jwt-decode';
import { showMessage } from './messageSlice';

const initialState = {
    loading: false,
    success: false,
    error: false,
    autoLogin: {
        loading: false,
        success: false,
        error: false
    },
    data: []
};

export const login = createAsyncThunk('login', async ({ email, password }, { dispatch, rejectWithValue, getState }) => {
    try {
        const response = await api.loginEmailPass(email, password);

        localStorage.setItem('jwtAccessToken', response?.data?.data?.accessToken?.token);

        return response?.data?.data;
    } catch (e) {
        dispatch(showMessage({ variant: 'error', message: e?.response?.data?.message || e?.message || 'Something went wrong' }));
        return rejectWithValue(e?.response?.data);
    }
});

export const getLoggedInUser = createAsyncThunk('getLoggedInUser', async ({ id }, { dispatch, rejectWithValue, getState }) => {
    try {
        const user = jwtDecode(localStorage.getItem('jwtAccessToken'));
        const response = await api.getLoggedInUser(user.id);

        return response?.data?.data;
    } catch (e) {
        return rejectWithValue(e?.response?.data);
    }
});

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearUser: (state, action) => initialState
    },
    extraReducers(builder) {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.success = false;
                state.error = false;
            })
            .addCase(login.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.error = false;
                state.data = action?.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false;
                state.success = false;
                state.error = action?.payload?.errors || action?.payload?.message;
            })
            .addCase(getLoggedInUser.pending, (state) => {
                state.autoLogin.loading = true;
                state.autoLogin.success = false;
                state.autoLogin.error = false;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.autoLogin.loading = false;
                state.autoLogin.success = true;
                state.autoLogin.error = false;
                state.data = action?.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.autoLogin.loading = false;
                state.autoLogin.success = false;
                state.autoLogin.error = action?.payload?.errors || action?.payload?.message;
            });
    }
});

export const { clearUser } = authSlice.actions;

export default authSlice.reducer;
// export const {} = analyticsSlice.actions;
