import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export function ProtectedRoutes() {
    return useRoutes([MainRoutes]);
}

export function PublicRoutes() {
    return useRoutes([AuthenticationRoutes]);
}
