import { combineReducers } from 'redux';

// ==============================|| REDUX - MAIN STORE ||============================== //
import authSlice from './slices/authSlice';
import { configureStore } from '@reduxjs/toolkit';
import customizationReducer from './customizationReducer';
import reusableSlice from './slices/reusableSlice';
import messageSlice from './slices/messageSlice';

const rootReducer = combineReducers({
    customization: customizationReducer,
    reusable: reusableSlice,
    message: messageSlice,
    auth: authSlice
});

const store = configureStore({
    reducer: rootReducer
});

export { store };
