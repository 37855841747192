import PropTypes from 'prop-types';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import ProfileSection from './ProfileSection';
import NotificationSection from './NotificationSection';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {
    const theme = useTheme();

    return (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
            {/* logo & toggler button */}

            {/* header search */}
            {/*<SearchSection />*/}
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginRight: '-200px' }}>
                <img style={{ maxWidth: '60px', marginBottom: 5 }} src="assets/logo/logo.png" alt="logo" />
            </div>

            {/* notification & profile */}
            {/*<NotificationSection />*/}
            <div>
                <ProfileSection />
            </div>
        </div>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
