// api.js
import qs from 'qs';
import http from './axios/http';
// /api/v1

const apiV1Prefix = '/v1';
const apiV2Prefix = '/v2';
const apiV2Supplier = `${apiV2Prefix}/supplier`;

export default {
    loginEmailPass: (email, password) => {
        return http.post(`${apiV2Prefix}/auth/login/email`, { email, password });
    },

    getLoggedInUser: (id) => {
        return http.get(`${apiV2Prefix}/user/${id}`);
    },
    getReusable: (code) => {
        return http.get(`${apiV2Prefix}/article/${code}`);
    },
    returnReusable: (codes) => {
        return http.post(`${apiV2Prefix}/article/return`, { codes });
    }
};
